import React, { useContext, useEffect, useState } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import formHook from '../../hooks/form-hook'
import { LangContext } from '../../context/lang-context'
import NoResults from '../../components/NoResults/NoResults'
import RouteForm from '../../components/Modals/RouteForm'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import { NotificationManager } from 'react-notifications'

export default function Routes({setType, type}) {


// constants...
const [routes, setRoutes] = useState([])
const { setHeaderData } = useContext(NavigationContext)
const {fetchMssqlApi} = fetchHook()
const {form, openForm, closeForm} = formHook()
const {TRL_Pack} = useContext(LangContext)
//==================


    //filter...
    const defaultFilter = {
        showIndexes: false,
        name: "routes",
        page: 1,
        disableIndexes: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50],
        visible: false,
        //sortByColumns: true,
        sortByColumns: false,
        //sortBy: '3 | asc | text',
        activeTags: [],
        columns: [
        {
            id: 3,
            name: "Aktywny",
            sortable: true,
            selectable: true,
            disabled: false,
            type: "threeStateBoolByActivity",
            value: "true",
            selectbar: "true"
        },
        ]
    }
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('locationRoutesFilter')) {
        return JSON.parse(localStorage.getItem('locationRoutesFilter'));
        }
        return defaultFilter;
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetFilter = () => {
        setFilter(defaultFilter);

    }
    const reportFilter = row =>
    filter.columns
        .filter(col => col.selectbar)
        .every(col => {
        const x = String(row[Object.keys(row)[col.id - 1]])
            .toLowerCase() === col.selectbar.toLowerCase()
        return x
        }
    )
    const search = () => {
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                col.selectbar = col.value
                return col
            })
        }))
    }
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    //===================


// data getters
const getRoutes = (isActive = true) => {
    fetchMssqlApi(`routes/?IsActive=${isActive}`, {}, res => {
        setRoutes(res)
    })
}

//==================


const getCheckboxFromBool = (bool) => (
    <div className='d-flex justify-content-center align-items-center'>
        <input
            type='checkbox'
            checked={bool}
            onChange={() => {}}
        />
    </div>
) 

const handleSubmit = (evt, data, setData ,locations) => {
    evt.preventDefault()
    const {isActive, name} = evt.target.elements
    const route = routes.find(route => route.RouteId === form)
    if (routes.filter(route => route.RouteId !== form).findIndex(route => route.Name === data.Name) !== -1) {
        NotificationManager.error("Taka nazwa już istnieje")
        setData(prev => ({...prev, Name: ""}))
        return
    }
    if (form !== "new" && String(data.IsActive) !== isActive.value ) {
        fetchMssqlApi(`route/${form}`, {method: "PUT", data: {...route,IsActive: isActive.value }}, () => {
            getRoutes()
            closeForm()
        })
        return
    }
    const payload = form === "new" ? {
        LocationsJSON: locations,
        Name: name.value
    } : {
        LocationsJSON: locations,
        Name: name.value,
        IsActive: isActive.value
    }
    if (form === "new") {
        fetchMssqlApi('route', {method: "POST", data: payload}, () => {
            getRoutes()
            closeForm()
        })
    } else {
        fetchMssqlApi(`route/${form}`, {method: "PUT", data: payload}, () => {
            getRoutes()
            closeForm()
        })
    }
}
//useEffects...
useEffect(() => {
    setHeaderData({text: "Trasy"})
    getRoutes()
}, [])
//==================
    return (
        <div style={{padding: "0px 50px"}}>
            <div>
                <ul className='nav nav-tabs machine-tabs mb-3'>
                <li className="nav-item">
                    <Link to='/machinelocations'>
                    <button
                    className={`nav-link btn ${type === "Locations"  && 'active'}`}
                    // onClick={() => setType("Locations")}
                    tabIndex="0">
                        Lokalizacje
                    </button>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to='/routes'>
                    <button
                    className={`nav-link btn active`}
                    // onClick={() => setType("Tracks")}
                    tabIndex="0">
                        Trasy
                    </button>
                    </Link>
                </li>

            </ul>
            </div>
            <Pagination 
                totalItems={routes.length}
                page={filter.page}
                handleSwitchPage={handleSwitchPage}
                rowsPerPage={filter.rowsPerPage}
                toggleFilter={toggleFilter}
                filterVisibility={filter.visible}
                resetFilter={resetFilter}
            />
            {filter.visible && (
                <FilterEx
                    filter={filter}
                    setFilter={setFilter}
                    columns={filter.columns}
                    // data={}
                    resetPage={resetPage}
                    resetFilter={resetFilter}
                    search={search}
                    toggleFilter={toggleFilter}
                />
            )}
            <div className='card-body' >
            {routes.length ? (
                <>
                <div>
                <button
                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                    onClick={openForm()}
                >
                    <i className="fas fa-plus mr-2" />
                    {TRL_Pack.locations.addProductButton}
                </button>
            </div>
                <table className='table table-striped border' style={{width: "100%"}}>
                    <thead>
                        <tr >
                            <th>Trasa</th>
                            <th style={{textAlign: "center"}}>Ilość lokalizacji</th>
                            <th style={{textAlign: "center"}}>Aktywna</th>
                        </tr>
                    </thead>
                    <tbody>
                        {routes
                        .filter(reportFilter)
                        .slice(
                            (filter.page - 1) * filter.rowsPerPage,
                            filter.page * filter.rowsPerPage
                        ).map((trace, idx) => (
                            <tr key={idx}>
                                <td>
                                    {trace.Name}
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        
                                        {trace.LocationsCount}
                                    </div>
                                </td>
                                <td>
                                    {getCheckboxFromBool(trace.IsActive)}
                                </td>
                                <td className='d-flex justify-content-end mb-2 mt-2 border-0'>
                                    <button className="btn btn-outline-primary btn-sm "
                                            onClick={openForm(trace.RouteId)}
                                            >
                                        Edytuj
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
            ) : (
                <NoResults buttonText={TRL_Pack.locations.addProduct} onClick={openForm()} />
            )} 
            </div>
            { form && (
                <RouteForm 
                    traceData={routes.find(route => route.RouteId === form)} 
                    handleClose={closeForm} 
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    )
}

import React, { useState, useEffect, useContext } from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import useForm from '../../../hooks/form-hook'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useFilter from '../../../hooks/filter-hook'

import SearchInput from '../../../components/SearchInput/SearchInput'
import NoResults from '../../../components/NoResults/NoResults'
import ClientForm from '../../../components/Modals/ClientForm'
import filterItems from '../../../util/filterItems'
import { LangContext } from '../../../context/lang-context'

const Clients = () => {
    const { fetchMssqlApi } = useFetch()
    const { TRL_Pack } = useContext(LangContext)
    const { setHeaderData } = useContext(NavigationContext)
    // const { TRL_Pack } = useContext(LangContext)
    const { searchedText, updateSearchedText } = useFilter()

    const { form, openForm, closeForm } = useForm()

    // const [clients, setClients] = useState(null)
    const [customers, setCustomers] = useState([])
    // const getClients = () => {
    //   fetchMssqlApi('clients', {}, clients => setClients(clients))
    // }
    const getCustomers = () => {
        fetchMssqlApi('/customers', {}, customers => setCustomers(customers))
    }

    const submitClient = evt => {
        evt.preventDefault()

        const { name, abbreviation, oldName } = evt.target.elements

        let path, method
        if (form !== 'new') {
            path = `client/${form}`
            method = 'PUT'
        } else {
            path = 'client'
            method = 'POST'
        }
        if (abbreviation.value.length > 3) return window.alert('abbr too long')
        if (abbreviation.value.length < 3) return window.alert('abbr too short')
        /**Array with abbr for checking abbr names */

        const arr = customers.map(customer => (customer.Abbreviation !== oldName.value) && customer.Abbreviation.toLowerCase())

        // console.log(arr, arr.includes(abbreviation.value.toLowerCase()))

        if (arr.includes(abbreviation.value.toLowerCase())) return window.alert('abbr already created')

        fetchMssqlApi(
            path,
            { method, data: { Name: name.value, Abbreviation: abbreviation.value || 'brak' } },
            () => {
                closeForm()
                // getClients()
                getCustomers()
            },
        )
    }

    useEffect(() => {
        setHeaderData({ text: TRL_Pack.navigation.clients })

        // getClients()
        getCustomers()
    }, [])
    const filteredClients =
        customers && customers.filter(({ Name }) => filterItems(searchedText, Name))

    return (
        <div>
            {customers && (
                <>
                    {customers.length ? (
                        <>
                            <SearchInput onSearch={updateSearchedText} />
                            {!filteredClients.length ? (
                                <NoResults buttonText="Dodaj klienta" onClick={openForm()} />
                            ) : (
                                <>
                                    <div>
                                        <button
                                            className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                                            onClick={openForm('new')}
                                        >
                                            <i className="fas fa-plus mr-2" /> {TRL_Pack.clients.addClient}
                                        </button>
                                    </div>
                                    <div className="overflow-auto">
                                        <table className="table table-striped border">
                                            <thead>
                                            <tr>
                                                <th>Klient</th>
                                                <th style={{ textAlign: 'center' }}>Baza danych</th>
                                                <th style={{ textAlign: 'center' }}>Liczba terminali Vendotek</th>
                                                <th style={{ textAlign: 'center' }}>Liczba terminali PAX</th>
                                                <th style={{ textAlign: 'center' }}>Liczba terminali NAYAX</th>
                                                <th style={{ textAlign: 'center' }}>Liczba terminali NEWLAND</th>
                                                <th style={{ textAlign: 'center' }}>Liczba powiązanych automatów z
                                                    terminalami
                                                </th>
                                                {/* <th>{TRL_Pack.machines.properties.delete}</th> */}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {filteredClients.map((customer, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <button
                                                            className="btn btn-link font-size-inherit text-reset text-decoration-none p-1"
                                                            onClick={openForm(customer.CustomerId)}
                                                        >
                                                            {customer.Name}
                                                        </button>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>{customer.Abbreviation.toLowerCase()}</td>
                                                    <td style={{ textAlign: 'center' }}>{customer.VendotekNo}</td>
                                                    <td style={{ textAlign: 'center' }}>{customer.PAXNo}</td>
                                                    <td style={{ textAlign: 'center' }}>{customer.NAYAXNo}</td>
                                                    <td style={{ textAlign: 'center' }}>{customer.NEWLANDNo}</td>
                                                    <td style={{ textAlign: 'center' }}>{customer.MachineNo}</td>

                                                    {/* <td> */}
                                                    {/* <button className="btn btn-link link-icon" onClick={() => 0}>
                                <i className="fas fa-trash text-danger" />
                              </button> */}
                                                    {/* <button
                              onClick={openForm(customer.CustomerId)}
                              className="btn btn-outline-primary btn-sm "
                              >Edytuj</button>
                            </td> */}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <NoResults buttonText={TRL_Pack.clients.addClient} onClick={openForm()} />
                    )}
                    {form && (
                        <ClientForm
                            // clientData={
                            //   form !== 'new'
                            //     && filteredClients.find(client => client.CustomerId === form)
                            //     //  : null
                            // }
                            clientData={form !== 'new' && filteredClients.find(client => client.CustomerId === form)}

                            handleSubmit={submitClient}
                            handleClose={closeForm}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default Clients
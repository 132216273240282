import React, { useEffect, useState } from 'react'
import fetchHook from '../../../../hooks/fetchMSSQL-hook'
import { NotificationManager } from 'react-notifications'
import { useHistory, Prompt } from 'react-router-dom/cjs/react-router-dom.min'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../../../assets/images/back_to_list_icon.svg'


export default function CloneMachine({setParam}) {
    // ==================== Constants =======================
    const {fetchMssqlApi} = fetchHook(),
    [machines, setMachines] = useState([]),
    newMachineObj = {
        name: "",
        serialNo: ""
    },
    cloneMachineObj = {
        templateMachineName: "",
        templateMachineId: null
    },
    [newMachine, setNewMachine] = useState(newMachineObj),
    [templateMachine, setTemplateMachine] = useState(cloneMachineObj),
    [changed, setChanged] = useState(false),
    history = useHistory()
    // ======================================================



    // ==================== Functions =======================
    const getMachinesList = () => {
        fetchMssqlApi('machines-list',{}, res => {
            setMachines(res)
        })
    }
    const handleClick = evt => {
        const {name} = evt.target
        if (Object.keys(newMachineObj).includes(name)) {
            setNewMachine(prev => ({...prev, [name]: ""}))
        }
        if (Object.keys(templateMachine).includes(name)) {

            setTemplateMachine({templateMachineId: null, [name]: ""})
        } 
    }
    const handleChange = evt => {
        const {name, value} = evt.target
        if (Object.keys(newMachineObj).includes(name)) {
            setNewMachine(prev => ({...prev, [name]: value}))
        }
        if (Object.keys(templateMachine).includes(name)) {
            const machine = machines.find(item => item.Name === value)
            machine ? setTemplateMachine({templateMachineId: machine.MachineId, [name]: [value]}) : setTemplateMachine({templateMachineId: null, [name]: [value]})
        } 
    }
    const handleSubmit = () => {
        const serialNos = machines && machines.map(machine => machine.SerialNo)
        if (serialNos.includes(newMachine.serialNo)) {
            setNewMachine(prev => ({...prev, serialNo: ""}))
            NotificationManager.error("Numer seryjny muśi być unikalny");
            return
        }
        fetchMssqlApi('new-machine/clone', {method: "POST", data: 
            {
                Name: newMachine.name || null
                , SerialNo: newMachine.serialNo
                , TemplateMachineId: templateMachine.templateMachineId
            }},
            res => {
                console.log("res: ",res.MachineId)
                setChanged(false)
                history.replace(res.MachineId)
                setParam(res.MachineId)
            }
            )
    }
    // ======================================================



    // ==================== UseEffects ======================
    useEffect(() => {
        getMachinesList()
    }, [])
    useEffect(() => {
        setChanged(true)
    }, [newMachine, templateMachine])
    // ======================================================
    return (
        <>
        <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
        <Link to='/machines'>
            <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                <img id='back' src={link}></img>
            </label>
        </Link>
            <div className='container'> 
                <div className='card'>
                    <div className='card-header'>
                        <h5 className='mb-0'>
                            Klonowanie automatu
                        </h5>
                    </div>
                    <div className='card-body'>
                        <div className='mb-2'>
                            <label htmlFor='Name'>
                                Nazwa
                            </label>
                            <input
                                value={newMachine.name}
                                onChange={handleChange}
                                onClick={handleClick}
                                name='name'
                                id='Name'
                                className=' form-control'
                                placeholder='Wpisz nazwę automatu...'
                                // style={{maxWidth: "50%"}}
                            />
                        </div>
                        <div className='mb-5'>
                            <label htmlFor='SerialNo'>
                                Nr seryjny
                            </label>
                            <input
                                value={newMachine.serialNo}
                                onChange={handleChange}
                                onClick={handleClick}
                                name='serialNo'
                                id='SerialNo'
                                className={` form-control ${!newMachine.serialNo && `invalid-input`}`}
                                placeholder='Wpisz numer seryjny automatu...'
                                // style={{maxWidth: "50%"}}
                            />
                        </div>
                        <div className='mb-4'>
                            <label htmlFor='CloneMachineName'>
                                Wybierz automat referencyjny
                            </label>
                            <input
                                value={templateMachine.templateMachineName}
                                onChange={handleChange}
                                onClick={handleClick}
                                name='templateMachineName'
                                id='CloneMachineName'
                                className={` form-control ${!templateMachine.templateMachineId && `invalid-input`}`}
                                placeholder='Wybierz automat...'
                                list='machines'
                                // style={{maxWidth: "50%"}}
                            />
                            <datalist id='machines'>
                                {machines && machines.map((machine, idx) => (
                                    <option key={idx} value={machine.Name}/>
                                ))}
                            </datalist>
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-fixed container' style={{bottom: "0px"}}>
                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        <button onClick={handleSubmit} className='btn btn-success btn-sm'disabled={!newMachine.serialNo || !templateMachine.templateMachineId  }>
                            Zapisz
                        </button>
                    </div>
            </div>
        </>
    )
}

import React from 'react'

export default function getZEinfo ({typ}) {
    const type = Object.keys(typ)[0]
    const values = Object.values(typ)[0]
    switch (type) {
        case '01':
        case '04':
        case '08':
        case '10':
        case '21':
            // return <div><h6><span className='badge badge-light'>{values.info}</span></h6></div>
            return <div>{values.info}</div>
        default:
            return <div>{values.info}</div>
        // case '07':
        //     return (
        //     <div className='d-lg-block d-flex flex-wrap'>
        //         <div>
        //             <div><h6><span className='badge badge-primary'>{values.info}</span></h6></div>
        //         </div>
        //         {/* <div>
        //             {values.stPTU && values.stPTU.map((ptu, idx) => (
        //                 <div className='d-flex justify-content-center' key={idx}> 
        //                     <div className='mr-2'>
        //                         {ptu.id}
        //                     </div>
        //                     <div>
        //                         {typeof(ptu.wart) === "number" ? `${ptu.wart/100}%` : ptu.wart } 
        //                     </div>
        //                 </div>))}
        //         </div> */}
        //     </div>
        //     )
        // case '09':
        //     return <div><h6>{values.info} <span className='badge badge-primary'>{values.walutaZm}</span></h6></div>
        // case '13':
        //     return <div><h6>{values.info} <span className='badge badge-success'>{values.firmwareId}</span></h6></div>
        // case '17':
        //     return <div><h6>{values.info}: {values.noweZr} </h6></div>
        // case '18':
        //     return <div><h6>{ values.info }</h6></div>
        // case '19':
        //     return <div><h6>{values.info} <span className='badge badge-warning'>{values.kluczTyp}</span></h6></div>
    }
} 
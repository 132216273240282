import React from "react";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ReportBtnForArr from '../../../../components/Buttons/ReportsBtn/ReportBtnForArr'
import getFiscalDoc from '../utils/getFiscalDoc'
import { NotificationManager } from 'react-notifications'
import { WKF_BASE_URL } from '../../../../config/config'

export default function RF (RF, idx,  state, setState) {
    return (
        <tr key={idx}>
            <td>
                <div className="d-flex align-items-center">
                    <Link
                        className={'badge-light btn-sm btn-info'}
                    >
                        {RF.FiscalDocType}
                    </Link>
                </div>
            </td>
            <td>
                <div>{RF.NrUnik}</div>
                <div>{RF.NrEwid}</div>
            </td>
            <td>
                <div>
                    {moment(RF.dataFisk).format('YYYY-MM-DD HH:mm:ss')}
                </div>
            </td>
            <td colSpan={2}>
                <div>
                    <div>
                        Kod urządu skarbowego: {RF.kodUS} 
                    </div>
                    <div>
                        Wersja oprogramowania: {RF.firmwareId} 
                    </div>
                    <div>
                        <h6><span className='badge badge-info'>Waluta: {RF.waluta}</span></h6>
                    </div>
                </div>
            </td>
            <td>
                {/* <ReportBtnForArr
                    clickFnc={() => getFiscalDoc(RF, btnStateHandler)}
                    state={btnState}
                    setState={btnStateHandler}
                    //overlayText={<b>Typ dokumentu: Paragon (PR)</b>}
                    btnColor={
                        `primary`
                    }
                    size='sm'
                    value={{Report: 1, Id: RF.FiscalDocId}}
                /> */}
                <ReportBtnForArr
                        state={state}
                        setState={setState}
                        value={{Report: 1, Id: RF.FiscalDocId}}
                        btnColor='primary'
                        clickFnc={() => {getFiscalDoc(RF, setState)}}
                    />
                <button
                    onClick={() => {
                        try {
                            window.navigator.clipboard.writeText(`${WKF_BASE_URL}/wkf/params?nrunik=${RF.NrUnik}&fiscaldoc=${RF.Hash}&client=${localStorage.getItem('clientId')}`)
                            NotificationManager.success("Link copied to clipboard")
                        } catch (e) {
                            NotificationManager.error(e)
                        }
                    }}
                    className={`d-inline m-1 btn btn-info float-right fas fa-xs fa-copy`}
                ></button>
            </td>
        </tr>
    )
}
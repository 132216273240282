import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import ReportBtnForArr from '../../../../components/Buttons/ReportsBtn/ReportBtnForArr'
import getFiscalDoc from '../utils/getFiscalDoc'
import { WKF_BASE_URL } from '../../../../config/config'

export default function RD(RD, idx, state, setState) {
    return (
        <tr key={idx}>
            <td>
                <div className="d-flex align-items-center">
                    <Link
                        className={'btn text-nowrap btn-sm btn-info'}
                    >
                        {RD.FiscalDocType}
                    </Link>
                </div>
            </td>
            <td>
                <div>{RD.NrUnik}</div>
                <div>{RD.NrEwid}</div>
            </td>
            <td>
                <div>
                    Utworzony {moment(RD.zakRap).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div>
                    Od {moment(RD.rozpSprzed).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div>
                    Do {moment(RD.zakSprzed).format('YYYY-MM-DD HH:mm:ss')}
                </div>
            </td>
            <td colSpan={2}>
                <div className="d-flex align-items-center justify-content-between flex-wrap pt-1 pb-1">
                    <div>

                        <div>
                            Suma brutto: {Intl.NumberFormat('pl-PL', {
                            style: 'currency',
                            currency: 'PLN',
                        }).format(RD.sumaBrutto) || '0,00zł'} ({RD.liczbaPar})
                        </div>
                        <div>

                            <div>Suma netto: {Intl.NumberFormat('pl-PL', {
                                style: 'currency',
                                currency: 'PLN',
                            }).format(RD.sumNetto) || '0,00zł'}
                            </div>
                        </div>
                        {/*(Waluta: {RD.waluta})*/}
                        <div>
                            Podatek: {Intl.NumberFormat('pl-PL', {
                            style: 'currency',
                            currency: 'PLN',
                        }).format(RD.sumaPod) || '0,00zł'}
                        </div>
                    </div>
                </div>

            </td>
            {/*<td>*/}
            {/*    <div>*/}
            {/*        <span className='badge badge-info'>Waluta: {RD.waluta}</span>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <span className='badge badge-success'>Liczba paragonów: </span>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <span className='badge badge-warning'>Anulowane paragony: </span>*/}
            {/*    </div>*/}
            {/*</td>   */}
            <td>
                {/* <Link
                    target={"_blank"}
                    to={`/wkf/params?nrunik=${RD.NrUnik}&fiscaldoc=${RD.Hash}&client=${localStorage.getItem('clientId')}`}
                    className={`d-inline m-1 btn btn-primary float-right fas fa-xs fa-download`}
                >
                </Link> */}
                <ReportBtnForArr
                    state={state}
                    setState={setState}
                    value={{ Report: 1, Id: RD.FiscalDocId }}
                    btnColor="primary"
                    clickFnc={() => getFiscalDoc(RD, setState)}
                />
                <button
                    onClick={() => {
                        try {
                            window.navigator.clipboard.writeText(`${WKF_BASE_URL}/wkf/params?nrunik=${RD.NrUnik}&fiscaldoc=${RD.Hash}&client=${localStorage.getItem('clientId')}`)
                            NotificationManager.success('Link copied to clipboard')
                        } catch (e) {
                            NotificationManager.error(e)
                        }
                    }}
                    className={`d-inline m-1 btn btn-info float-right fas fa-xs fa-copy`}
                >
                </button>
            </td>
        </tr>
    )
} 
import React, { useState, useContext, useEffect } from 'react'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import { API_URL } from '../../../config/config'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { LangContext } from '../../../context/lang-context'
import TextInput from '../../../components/FormElements/TextInput'
import clearInput from '../../../util/cleanInput'
import sampleProduct from "../../../assets/images/sample-product.svg"
import { Link, Prompt } from 'react-router-dom/cjs/react-router-dom'
import { NavigationContext } from '../../../context/navigation-context'
import { NotificationManager } from 'react-notifications'
import notificationContext from '../../../context/notification-context'

export default function Product({}) {
    const param = useParams()
    const form = param.ean
    const { fetchMssqlApi } = useFetch()
    const {setHeaderData} = useContext(NavigationContext)
    const {
        TRL_Pack: { products }
    } = useContext(LangContext)
    const initialValue = (productData) => {
        if (productData) { return `${API_URL}/${productData.Image}` }
        else {
            return null
        }
    }
    const shared = { ean: true, image: true, name: true, desc: true }
    const notShared = { ean: true, image: false, name: false, desc: false, vatId: false, lastPriceNetto: false }
    const [allVat, setVat] = useState(null)

    const getVATCode = (vatId) => {
        if(!allVat) return null
        let code = null;

        if (vatId !== '' && allVat.find(obj => obj.VATId === vatId) !== undefined)
            code = allVat.find(obj => obj.VATId === vatId).Code

        return code;

    }
    const [productData, setProductData] = useState({})
    const [productChanged, setProductChanged] = useState(false)
    const [data, setData] = useState({})
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [groups, setGroups] = useState([])
    const [recipes, setRecipes] = useState([])

    const format =  /^\d+(\,\d{1,2})?$/
    useEffect(() => {
        setData({
            EAN: productData.EAN || "",
            Image: productData.Image || null,
            Name:  productData.Name || "",
            Description: productData.Description || "",
            VATId:  productData.VATId || 4,
            VAT: getVATCode(productData.VATId),
            LastPriceNetto: productData.LastPriceNetto || "",
            IngredientQuantity: productData.IngredientQuantity || "",
            ProductGroupId: productData.ProductGroupId || "",
            ProductGroupName: productData.ProductGroupName || "",
            IsIngredient: productData.IsIngredient,
            Code: productData.Code,
            IsActive: productData.IsActive,
            Deposit: productData.Deposit,
        })
        setImage(initialValue(productData))
        setProductChanged(false)
    }, [productData])
    const [image, setImage] = useState(initialValue(productData))
    const [categoriesSection, setCategoriesSection] = useState(true)
    const [changedImage, setChangedImage] = useState(false)
    const [isAvailableToSave, setIsAvailableToSave] = useState(true)
    const toggleCategoriesSection = () => setCategoriesSection(prev => !prev)
    const [productCategories, setProductCategories] = useState({
        initial: [],
        added: [],
        deleted: [],
        data: []
    })
    // const [disableRemove, setDisableRemove] = useState(false)
    // useEffect(() => {
    //     (!productCategories.data.every(cat => cat.CategoryProductId === null) 
    //     || 
    //     productCategories.added.length) 
    //     ? setDisableRemove(true) 
    //     : setDisableRemove(false)
    // }, [productCategories])
    useEffect(() => {
        // const {Name} = data
        return (!String(data.Name).length) ?  setIsAvailableToSave(false) : setIsAvailableToSave(true)
    }, [data])
    const getProductData = () => {
        fetchMssqlApi(`/product/${param.ean}`, {}, res => setProductData(res))
    }
    const getGroups = () => {
        fetchMssqlApi('products/groups', {}, res => setGroups(res))
    }
    const getRecipes = () => {
        fetchMssqlApi(`products/recipe/${param.ean}`, {}, res => setRecipes(res))
    }
    // const [productsVat, setProductsVat] = useState({});
    const [isIngredient, setIsIngredient] = useState(productData && productData.IsIngredient || false)
    const getVat = () => {
        fetchMssqlApi('products-vat', {}, data => {setVat(data)})
    }
    const focusHandler = evt => {
        setProductChanged(true)
        setUnsavedChanges(true)
        const {name} = evt.target
        if(name === "ProductGroupName") setData(prev => ({...prev, ProductGroupId: ""}))
        setData(prev => ({...prev, [name]: ""}))
    }
    const handleStatus = () => {
        // if(!productCategories.initial.every(c => !c) ) {
        //     alert("Produkt jest zapisany do automatu \n Usuń go żeby deaktywować")
        // }
        // else {
        // return disableRemove && data.IsActive
        // ? NotificationManager.warning("Produkt jest zapisany do kategorii", "usuń powiązanie żeby deaktytować produkt", 3000)
        fetchMssqlApi(`product/${param.ean}`, {method: "PUT", data: {...productData, IsActive: String(!productData.IsActive)}}, () => setData(prev => ({...prev, IsActive: !prev.IsActive})))
        // }
    }
    const getProductCategories = () => {
        fetchMssqlApi(`categories/${form}`, {}, productCategories => {
            setProductCategories(prev => ({
                ...prev,
                data: productCategories,
                initial: productCategories.map(category => category.CategoryProductId)
            }))
        })
        setProductChanged(false)
    }
    // useEffect(() => {
    //     if(!productChanged) setProductChanged(true)
    // }, [data])
    // useEffect(() => {

    // }, [data, productCategories])
    const toggleProductCategory = (id, categoryId) => () => {
        if (!data.IsActive) {
            NotificationManager.warning("Nie możesz dodać nieaktywnego produktu do kategorii")
            return
        }
        if(!unsavedChanges) setUnsavedChanges(true)
        if (!productChanged) setProductChanged(true)
        setProductCategories(prev => {
            if (prev.deleted.includes(id))
                return { ...prev, deleted: prev.deleted.filter(cId => cId !== id) }
            else if (prev.added.includes(id))
                return { ...prev, added: prev.added.filter(cId => cId !== id) }
            else if (categoryId != null)
                return { ...prev, deleted: prev.deleted.concat(id) }
            else return { ...prev, added: prev.added.concat(id) }
        })
    }
    const getGroupId = groupName => {
        const group = groups.find(group => group.Name === groupName)
        return group ? group.ProductGroupId : null
    }
    const handleChangeImage = evt => {
        evt.preventDefault()
        setProductChanged(true)

        if (evt.target.files[0]) {
            setChangedImage(true)
            const reader = new FileReader()
            reader.readAsDataURL(evt.target.files[0])
            reader.onloadend = () => setImage(reader.result)
        } else { setImage(null); setChangedImage(false) }
    }
    const handleChange = evt => {
        setUnsavedChanges(true)
        const { name, value } = evt.target
        if(!unsavedChanges) {setUnsavedChanges(true)}
        if (!productChanged) {
            setProductChanged(true)
        }
        if(name === "VAT") setData(prev => ({...prev, VAT: +value, VATId: getVATId(value)}))
        else if(name === "LastPriceNetto") setData(prev => ({...prev, LastPriceNetto: parseFloat(value)}))
        else if(name === "ProductGroupName") setData(prev => ({...prev, ProductGroupName: value, ProductGroupId: getGroupId(value)}))
        else if(name === "Deposit") setData(prev => ({...prev, Deposit: value}))
        else setData(prev => ({ ...prev, [name]: value }))
    }
    const handleSubmit = evt => {

        evt.preventDefault()
        const unFormat  = (value) => {
            value = value.replace("zł", "");
            value = value.replace(",", ".")
            value = parseFloat(value);
            return(value);
        }
        setProductChanged(false)

        // const image = document.getElementsByName('image-upload')
        const { EAN, Name, image, Description, VAT, LastPriceNetto, IngredientQuantity, ProductGroupName, Deposit} = evt.target.elements
        if((/ {2,}/g).test(Name.value)) {
            NotificationManager.error("Wpisz prawidlową nazwę", "Nie możesz użyć powyżej dwoch spację po kolei")
            return
        }
        const formData = new FormData()
        if (+Deposit?.value < 0) {
            NotificationManager.error("Kaucja nie moźe być ujemną")
            Deposit.value = ""
            return
        }
        formData.append('Ean', EAN.value)
        formData.append('Name', Name.value)
        formData.append('Description', Description?.value || "")
        formData.append("IsSubscribed", 0)
        formData.append('vatId', getVATId(VAT.value))
        formData.append('lastPriceNetto', LastPriceNetto.value)
        formData.append('IngredientQuantity', IngredientQuantity?.value || "")
        formData.append('IsActive', data.IsActive)
        formData.append('ProductGroupId', data.ProductGroupId)
        formData.append('IsIngredient', data.IsIngredient)
        formData.append('Code', data.Code)
        formData.append('Deposit', Deposit?.value || "")
        // console.log(isActive.value)
        // formData.append("ParentProductGroup", group.value)
        //   if(!productData?.GroupId && group.value && !productData?.ProductGroupId) {
        //   fetchMssqlApi('product/group', {method:"POST", data: {
        //     IsSnack: false,
        //     IsCoffee: false,
        //     DefaultProductId: productData?.ProductId || "",
        //     ParentProductGroup: group.value,
        //     ProductName: name.value
        //   }, hideNotification: true}, () => {})
        // } else if (productData && productData.GroupId !== +group.value) {
        //   fetchMssqlApi(`product/groups/${productData.ProductId}`, {method: "PUT", data: {ParentProductGroupId: group.value}, hideNotification: true}, () => {})
        // }
        // else if (productData && productData.GroupId && !group.value) {
        //   fetchMssqlApi()
        // }
        if(data.ProductGroupName && !data.ProductGroupId) {
            NotificationManager.error("Takiej grupy nie istnieje")
            setData(prev => ({...prev, ProductGroupName: ""}))
            return
        }
        if (image.files[0]) formData.append('Image', image.files[0])
        else if (productData) {
            formData.append('Image', productData.Image)
        }
        let path, method
        if (!productData) {
            path = 'product'
            method = 'POST'
        }
        else if (productData.IsSubscribed) {
            path = 'product'
            method = 'POST'
        } else {
            path = `product/${productData.EAN}`
            method = 'PUT'
        }
        if (form === "new" || !productData.IsSubscribed) {
            fetchMssqlApi(path, { method, data: formData }, () => {
                    productCategories.added.forEach(categoryId => {
                        fetchMssqlApi('category-product', {
                            method: 'POST',
                            data: { CategoryId: categoryId, Ean: EAN.value},
                            hideNotification: true
                        }, res => setProductCategories(prev => ({...prev,
                            data: prev.data.map(category => (category.CategoryId === categoryId) ? ({...category, CategoryProductId: res.rows.CategoryProductId, EAN: res.rows.EAN}) : category),
                            added: prev.added.filter(id => id !== categoryId)})))
                    })
                    productCategories.deleted.forEach(categoryId => {
                        const { CategoryProductId } = (productCategories.data.find(pc => { if (pc.CategoryId === categoryId) return pc.CategoryProductId }))
                        fetchMssqlApi(
                            `category-product/${CategoryProductId}`,
                            {
                                method: 'DELETE',
                                data: {},
                                hideNotification: true
                            },
                            () => setProductCategories(prev => ({...prev,
                                deleted: prev.deleted.filter(p => p != categoryId),
                                data: prev.data.map(p => (p.CategoryProductId === CategoryProductId) ? ({...p, CategoryProductId: null}) : p)}))
                        )
                    })
                    setUnsavedChanges(false)
                    //   handleClose()
                    //   getProducts()


                    // fetchMssqlApi(`categories/${form}`, {}, productCategories => {
                    //     setProductCategories(prev => ({
                    //         deleted: [],
                    //         added: [],
                    //         data: productCategories,
                    //         initial: productCategories.map(category => category.CategoryProductId)
                    //     }))
                    // })
                }
            )
        }
        else {
            if (productCategories.added.length > 0)
            {productCategories.added.forEach(categoryId => {
                    fetchMssqlApi('category-product', {
                        method: 'POST',
                        data: { CategoryId: categoryId, Ean: productData.EAN, }
                    })
                }
            )
                setUnsavedChanges(false)}
            else if (productCategories.deleted.length > 0) {
                productCategories.deleted.forEach(categoryId => {
                        const { CategoryProductId } = (productCategories.data.find(pc => { if (pc.CategoryId === categoryId) return pc.CategoryProductId }))
                        fetchMssqlApi(
                            `category-product/${CategoryProductId}`,
                            {
                                method: 'DELETE'
                            },
                        )
                    }
                )
            }
            fetchMssqlApi(`categories/${form}`, {}, productCategories => {
                setProductCategories(prev => ({
                    deleted: [],
                    added: [],
                    data: productCategories,
                    initial: productCategories.map(category => category.CategoryProductId)
                }))
            })
            // handleClose()
            // getProducts()

            //     setProductChanged(false)
            //     setUnsavedChanges(false)

            // const deletedCategories = productCategories.deleted
            // const addedCategories = productCategories.added
            // const initialCategories = productCategories.initial
            // const categories = productCategories.data
            // const deleteCategories = async id => {
            //     const { CategoryProductId } = productCategories.data.find(pc => (pc.CategoryId === id) && pc.CategoryProductId )
            //     if (!CategoryProductId) {
            //         throw new Error("Product not found")
            //     }
            //         await fetchMssqlApi(`category-product/${CategoryProductId}`,
            //             {
            //                 method: 'DELETE',
            //                 data: {},
            //                 hideNotification: true
            //             }, () => setProductCategories(prev => ({...prev, added: prev.added.filter(p => p !== id)})))
            // }
            // const postCategories = async (categoryid, ean) => {
            //     await fetchMssqlApi('category-product', {
            //         method: 'POST',
            //         data: { CategoryId: categoryid, Ean: ean, }
            //     }, () => setProductCategories(prev => ({...prev, deleted: prev.deleted.filter(p => p !== categoryid)})))
            // }
            // const getUpdateData = async () => {
            //     console.log(productCategories)
            //     await fetchMssqlApi(`categories/${form}`, {}, productCategories => {
            //         setProductCategories(() => ({
            //             deleted:[],
            //             added:[],
            //             data: productCategories,
            //             initial: productCategories.map(category => category.CategoryProductId)
            //         }))
            //     })
            // }
            // const categoriesOperation = async () => {
            //     await deletedCategories && deletedCategories.forEach(id => deleteCategories(id))
            //     await addedCategories && addedCategories.forEach(id =>postCategories(id, EAN.value))
            //     await setCategoriesSection(prev => ({...prev, deleted: [], added: []}))
            //     await getUpdateData()
            //     console.log(productCategories)
        }
        // categoriesOperation()
    }
    const selectCategories = (category) => {
        if ((category.CategoryProductId == null) && productCategories.added.includes(category.categoryId)) return "list-group-item-success"
        else if ((category.CategoryProductId != null) && (!productCategories.deleted.includes(category.CategoryId))) return "list-group-item-success"
        else if (productCategories.added.includes(category.CategoryId)) return "list-group-item-success"
        else return ""
    }

    const getVATId = (code) => {
        let vatId = null;

        if (code !== '' && allVat.find(obj => obj.Code === code) !== undefined)
            vatId = allVat.find(obj => obj.Code === code).VATId

        return vatId;

    }

    useEffect(() => {
        setHeaderData({text: "Podgląd produktu"})
        getVat()
        if (productData) { getProductCategories() }
        getProductData()
        getGroups()
        // setProductChanged(false)
        // getAllGroups()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if(productData && productData.IsIngredient) getRecipes()
    }, [productData])
    // useEffect(() => {
    //     console.log(recipes)
    // }, [recipes])
    return (
        data && (
            <div className='d-inline-flex justify-content-center'>
                <Prompt when={unsavedChanges} message={"Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"}/>
                <div className='container pb-5 '>
                    <Link to='/products'>
                        <button className="btn btn-link ml-1 text-decoration-none mb-3">
                            <i className="fas fa-arrow-left mr-2" />
                            Wróć
                        </button>
                    </Link>
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 md-6 mb-0" >
                            <div className="card h-80">
                                <h5 className="card-header">Ustawienia</h5>
                                <div className="card-body d-flex flex-column justify-content-center">
                                    <form id="product-form" autoComplete="off" onSubmit={handleSubmit}>
                                        <div className="row mb-2">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                EAN
                                            </div>
                                            <div className="col-lg-7 my-auto">

                                                <TextInput
                                                    className="mx-auto mx-lg-0"
                                                    // style={{ maxWidth: 275 }}
                                                    name="EAN"
                                                    value={productData.EAN}
                                                    // handleChange={handleChange}
                                                    minLength={1}
                                                    type="number"
                                                    // maxLength={50}
                                                    required
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                Nazwa
                                            </div>
                                            <div className="col-lg-7 my-auto">

                                                <input
                                                    className={`form-control ${!data.Name && `invalid-input`} form-control-sm mx-auto mx-lg-0`}
                                                    // style={{ maxWidth: 275 }}
                                                    name="Name"
                                                    value={data.Name}
                                                    onChange={handleChange}
                                                    minLength={1}
                                                    onFocus={focusHandler}
                                                    defaultValue={productData.Name || ''}
                                                    // maxLength={50}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                Grupa
                                            </div>
                                            <div className="col-lg-7 my-auto">

                                                <input
                                                    className={`form-control form-control-sm mx-auto mx-lg-0`}
                                                    // style={{ maxWidth: 275 }}
                                                    name="ProductGroupName"
                                                    value={data.ProductGroupName}
                                                    onChange={handleChange}
                                                    onFocus={focusHandler}
                                                    defaultValue={productData.ProductGroupName || ''}
                                                    list="groupList"
                                                />
                                                <datalist id="groupList">
                                                    {groups && groups.map((group, idx) => group.IsActive && group.ConnectedProduct && (
                                                        <option key={idx} value={group.Name} />
                                                    ))}
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                Ostatnia cena netto
                                            </div>
                                            <div className="col-lg-7 my-auto">
                                                <input
                                                    // style={{ maxWidth: 100 }}
                                                    className="form-control form-control-sm mx-auto mx-lg-0"
                                                    name="LastPriceNetto"
                                                    value={data.LastPriceNetto}
                                                    defaultValue={data.LastPriceNetto}
                                                    onChange={handleChange}
                                                    type="number"
                                                    min={0}
                                                    step={0.01}
                                                    onFocus={focusHandler}
                                                />
                                            </div>
                                        </div>
                                        {data.IsIngredient && (

                                            <div className="row mb-3">
                                                <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                    Ilość składnika
                                                </div>
                                                <div className="col-lg-7 my-auto">
                                                    <input
                                                        name="IngredientQuantity"
                                                        className={`form-control form-control-sm mx-auto mx-lg-0`}
                                                        onChange={val => {
                                                            setUnsavedChanges(true)
                                                            setProductChanged(true)
                                                            const value = val.target.value.replace(/\D/g, '')
                                                            setData((prev) => ({ ...prev, IngredientQuantity: value }))
                                                        }}
                                                        defaultValue={productData && productData.IngredientQuantity || ''}
                                                        value={data.IngredientQuantity}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {allVat && (
                                            <div className="row mb-3">
                                                <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                    Stawka VAT
                                                </div>
                                                <div className="col-lg-7 my-auto">
                                                    <select
                                                        // defaultValue={getVATCode(productData.VATId)}
                                                        value={data && data.VAT}
                                                        name="VAT"
                                                        className="form-control form-control-sm mx-auto mx-lg-0"
                                                        onChange={handleChange}
                                                    >
                                                        {allVat && allVat.map(vat => (
                                                            <option key={vat.VATId} value={getVATCode(vat.VATId)}
                                                                    selected={vat.VATId === productData.VATId}>
                                                                {vat.Code + '%'}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mb-3">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                Opis
                                            </div>
                                            <div className="col-lg-7 my-auto">
                                            <textarea
                                                name="Description"
                                                className={`form-control form-control-sm mx-auto mx-lg-0`}
                                                onChange={val => {
                                                    setUnsavedChanges(true)
                                                    setProductChanged(true)
                                                    const value = val.target.value
                                                    setData((prev) => ({ ...prev, Description: value }))
                                                }}
                                                defaultValue={productData && productData.Description || ''}
                                                value={data.Description}
                                                rows={5}
                                                // onFocus={clearInput}
                                            />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                Kod dodatkowy
                                            </div>
                                            <div className="col-lg-7 my-auto">
                                                <input
                                                    value={data.Code}

                                                    name="Code"
                                                    className={`form-control form-control-sm mx-auto mx-lg-0`}
                                                    onChange={val => {
                                                        setUnsavedChanges(true)
                                                        setProductChanged(true)
                                                        const value = val.target.value.replace(/[^A-Za-z0-9]/g)
                                                        setData((prev) => ({ ...prev, Code: value }))
                                                    }}
                                                    defaultValue={productData && productData.Code || ''}
                                                    onFocus={focusHandler}
                                                    // value={data.Code}
                                                />
                                            </div>
                                        </div>
                                        {data && !data.IsIngredient && (
                                            <div className="row mb-3">
                                                <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                    Kaucja
                                                </div>
                                                <div className="col-lg-7 my-auto">
                                                    <input
                                                        value={data.Deposit}

                                                        name="Deposit"
                                                        className={`form-control form-control-sm mx-auto mx-lg-0`}
                                                        defaultValue={data.Deposit}
                                                        onChange={handleChange}
                                                        type="number"
                                                        step={0.01}
                                                        max={1000}
                                                        onFocus={focusHandler}
                                                        // value={data.Code}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mb-3">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                Zdjęcie
                                            </div>
                                            <div className="col-lg-7 my-auto">
                                                <input
                                                    type="file"
                                                    className="custom-file-input form-control-sm mx-auto mx-lg-0"
                                                    name="image"
                                                    onChange={handleChangeImage}
                                                    id="image-upload"
                                                    accept="image/x-png"
                                                    // disabled={form === "new" ? "" : disabled.name}
                                                />
                                                <label className="custom-file-label" htmlFor="image-upload">
                                                    Choose file
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-lg-4 mb-2 mb-lg-0 text-lg-right">
                                                Składnik
                                            </div>
                                            <div className="col-lg-7 my-auto">
                                                <input
                                                    type="checkbox"
                                                    checked={data.IsIngredient}
                                                    onClick={() => {
                                                        setUnsavedChanges(true)
                                                        setProductChanged(true)
                                                        setData(prev => ({
                                                            ...prev,
                                                            IsIngredient: !prev.IsIngredient,
                                                            IngredientQuantity: null,
                                                            Deposit: null,
                                                        }))

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card h-20">
                                    <h5 className="card-header">Kategorie</h5>
                                    <div className="col-lg-12">
                                        {categoriesSection && (
                                            <div className="row mt-3 no-gutters categories-section">
                                                {productCategories.data.map((category, idx) => (
                                                    <div
                                                        key={idx}
                                                        className={`col-6 pl-3 font-weight-bolder list-group-item selectCategories ${selectCategories(category)}`}
                                                        onClick={toggleProductCategory(category.CategoryId, category.CategoryProductId)}
                                                    >
                                                        {category.Name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 md-6 mb-md-0" >

                            <div className="card ">
                                {/* <div className="text-center"> */}
                                <h5 className="card-header">Zdjęcie</h5>
                                <div width={window.innerWidth > 500 ? "500" : window.innerWidth - 80}
                                     height={window.innerWidth > 500 ? "500" : window.innerWidth - 80}
                                     className='d-flex justify-content-center'>

                                    {(productData || image) && (
                                        <img alt="#"
                                             src={image || API_URL + data.Image}
                                             onError={evt => (evt.target.src = sampleProduct)}
                                             width={window.innerWidth > 500 ? "500" : window.innerWidth - 80}
                                             height={window.innerWidth > 500 ? "500" : window.innerWidth - 80}
                                        />
                                    )}
                                </div>
                            </div>
                            {productData.IsIngredient && (
                                <div className='card'>
                                    <h5 className="card-header">Receptury</h5>
                                    <div className='col-lg-12'>
                                        <div className="row mt-3 no-gutters categories-section mb-3">
                                            {recipes && recipes.length ? recipes.map((recipe, idx) => (
                                                <Link
                                                    to={{pathname:`/recipe/${recipe.RecipeId}`,
                                                        state: {ingredient: recipe.RecipeProductId,
                                                            product: param.ean,
                                                            IsActive: recipe.IsActive}}}
                                                    key={idx}
                                                    className={`col-6 pl-3 font-weight-bolder list-group-item selectCategories ${recipe.IsActive ? `list-group-item-success` : `list-group-item-danger`}`}
                                                >
                                                    {recipe.RecipeName}
                                                </Link>
                                            )) : (<div className={`col-12 pl-3 font-weight-bolder list-group-item`}>
                                                Składnik nie jest przepisany do żadnej receptury
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className='position-fixed container m-20' style={{bottom: "0px", zIndex:"1"}}>

                    <div className="card-footer text-center pr-3 pl-0" style={{backgroundColor:"#cbcbcb",
                        borderTopRightRadius: "calc(0.25rem - 1px)",
                        borderTopLeftRadius: "calc(0.25rem - 1px)"}}>
                        {/* <button
            onClick={() => dele(.RecipeId)} 
            className={`btn btn-${recipe.IsActive ? `danger` : `success`} mt-auto w-20 btn-sm mr-5`} >{recipe.IsActive ? "Usuń" : "Ponów"}</button> */}
                        <button className={`btn ${data.IsActive ?  `btn-danger`  : `btn-success`} w-20  btn-sm mr-5`}
                                onClick={handleStatus}
                            // disabled={data.IsActive && disableRemove}
                        >
                            {data.IsActive ? "Deaktywuj" : "Aktywuj"}
                        </button>
                        <button className={`btn btn-primary w-20  btn-sm`}
                            // onClick={evt => handleSubmit(evt)}
                                type="submit"
                                form="product-form"
                                disabled={!(isAvailableToSave && productChanged)}
                        >
                            Zapisz
                        </button>
                    </div>
                </div>
            </div>
        )
    )
}

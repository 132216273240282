import React from "react"


// TODO: dodac translacje
export default function getPaymentType(paymentTypeId) {
    switch (paymentTypeId) {
        case 0:
            return (<div className="badge badge-secondary p-1">Typ płatności: Gotówka, gotówkowa</div>)
        case 1:
            return (<div className="badge badge-info p-1">Typ płatności: Karta, bezgotówkowa</div>)
        case 3:
            return (<div className="badge badge-primary p-1">Typ płatności: BLIK, bezgotówkowa</div>)
        default:
            return <div className='content p-1  ' dangerouslySetInnerHTML={{__html: paymentTypeId}}></div>
    }
}